/**
 * Компонент активации текстовых полей, если выбран соответствующий чекбокс или радиокнопка.
 * Актуален в анкетах и опросах
 */

(function () {
    "use strict";

    function Group(el) {
        const element = el;
        let textarea = null;

        /**
         * Во время инициализации связывается элемент и текстовое поле.
         * Если что-то идет не так, что держать продолжать работу с текущим классом нельзя,
         * поэтому init возвращает результат инициализации
         * @returns {boolean}
         */
        this.init = () => {
            try {
                const txtId = element.getAttribute('data-textarea');
                textarea = document.getElementById(txtId);
                this.check();
                return Boolean(textarea);
            } catch (event) {
                return false;
            }
        };

        this.check = () => {
            if (element.checked) {
                this.enable();
            } else {
                this.disable();
            }
        };

        this.enable = function () {
            textarea.removeAttribute('disabled');
        };

        this.disable = function () {
            textarea.setAttribute('disabled', 'true');
        };
    }

    const elements = document.querySelectorAll('.js-disable-element');

    if (!elements.length) {
        return;
    }

    const elementsClasses = [].reduce.call(elements, function(acc, el) {
        const elClass = new Group(el);
        if (elClass.init()) {
            acc.push(elClass);
        }
        return acc;
    }, []);

    document.addEventListener('change', function (event) {
        elementsClasses.forEach(function (el) {
            el.check();
        });
    });
})();