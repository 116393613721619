import $ from 'jquery';
import './region-map.scss';
import './region-map-wcag.scss';

class RegionMap {

    constructor () {

        this.linkArea = $('.region-map__li');
        this.map = $('#js-region-map');
        this.onItemMouseOver();
        this.onItemMouseOut();
    }

    /**
     * Обработчик mouseover на эллементах li, списка реигонов
     */
    onItemMouseOver() {
        this.linkArea.on('mouseover', (e) => {
            const areaName = e.currentTarget.getAttribute('data-name-area');
            this.setColorMap(areaName);
        });
    }

    /**
     * Обработчик mouseout на эллементах li, списка реигонов
     */
    onItemMouseOut() {
        this.linkArea.on('mouseout', (e) => {
            const areaName = e.currentTarget.getAttribute('data-name-area');
            this.unsetColorMap(areaName);
        });
    }

    /**
     * Устанавливает цвет району на svg карте
     * @param areaName - название района полученное из аттрибута data-name-area
     */
    setColorMap(areaName) {
        this.map.find(`[data-area=${areaName}]`).addClass('region-map__item_active');
    }

    /**
     * Снимает цвет району на svg карте
     * @param areaName - название района полученное из аттрибута data-name-area
     */
    unsetColorMap(areaName) {
        this.map.find(`[data-area=${areaName}]`).removeClass('region-map__item_active');
    }
}

new RegionMap();