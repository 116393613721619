/**
 * @TODO: Избавиться от var в объявлениях переменных.
 */

/**
 * Возвращает cookie с именем name, если есть, если нет, то undefined.
 * @param name
 * @returns {any}
 */
const getCookie = function(name) {
    var matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
};

/**
 * Запись в document.cookie.
 * @param name — название cookie.
 * @param value — значение cookie.
 * @param options — объект с дополнительными свойствами для установки cookie (см. спецификацию document.cookie).
 */
const setCookie = function(name, value, options) {
    options = options || {};

    var expires = options.expires;

    if (typeof expires == "number" && expires) {
        var d = new Date();
        d.setTime(d.getTime() + expires * 1000);
        expires = options.expires = d;
    }
    if (expires && expires.toUTCString) {
        options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);

    var updatedCookie = name + "=" + value;

    for (var propName in options) {
        updatedCookie += "; " + propName;
        var propValue = options[propName];
        if (propValue !== true) {
            updatedCookie += "=" + propValue;
        }
    }

    document.cookie = updatedCookie;
};

/**
 * Удаляем cookie вызовом setCookie с датой в прошлом.
 * @param name
 */
const deleteCookie = function(name) {
    setCookie(name, "", {
        expires: -1
    })
};

export {getCookie, setCookie};
