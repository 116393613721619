import "./show-more.scss";

const showBtn = document.querySelectorAll('.show-more');

if (showBtn.length) {
    [].forEach.call(showBtn, function (btn) {

        var parent = btn.closest(".show-container")

        btn.addEventListener('click', function() {
            parent.classList.add('active');
            btn.classList.add('hidden');
        });

        window.addEventListener('resize', function() {
            parent.classList.remove('active');
            btn.classList.remove('hidden');
        });

     });
};