import './content-style.scss';
import './content-style-wcag.scss';

/**
 * Оборачиваем таблицы в контенте для горизонтального скролла, если таблица превышает доступную ширину
 * @param table
 * @constructor
 */
function AdaptiveTable(table) {
    var wrap = document.createElement('div');
    wrap.classList.add('adaptive-table-wrapper');
    table.parentElement.insertBefore(wrap, table);
    wrap.appendChild(table);
}

function addClassContenttable(table) {
    return $(table).hasClass('contenttable') ? null : $(table).addClass('contenttable');
}

var tables = document.querySelectorAll('.content-style table');

[].forEach.call(tables, function (table) {
    addClassContenttable(table);
    new AdaptiveTable(table);
});
