import './header.scss';
import './header-wcag.scss';
import openWcagPanel from '../../ui/wcag/wcag-panel/wcag-panel.js';

const wcag = $('#wcag-panel'),
      wcagTabindex = $('#wcag-panel [tabindex="-1"]'),
      wcagFocus = $('#fontsize-normal'),
      wcagBtn = $('.wcag-btn');

openWcagPanel();

$(wcagBtn).on('click', function() {

    if (!$(wcag).hasClass('active')) {
        $(wcag).css('display', 'block');
    };

    $(wcag).toggleClass('active');

    if ($(wcag).hasClass('active')) {
        $(wcag).animate({height: '101px'});
        $(wcagTabindex).removeAttr('tabindex');
        $(wcagFocus).focus();
        $(wcagBtn).prop('title', "Закрыть настройки версии для людей с ограниченными возможностями");
    }  else {
        $(wcag).animate({height: '0'});
        $(wcagTabindex).prop('tabindex', -1);
        $(wcagBtn).prop('title', "Открыть настройки версии для людей с ограниченными возможностями");
        $(wcagBtn).focus();
    };

    $(this).toggleClass('wcag-btn_active');

    $('body').toggleClass('wcag_visible');

});

$(wcagBtn).keypress(function (e) {
    let code = e.keyCode || e.which;
    if (code == 13) {
        $(wcagBtn)[0].click();
    }
});
